/* You can add global styles to this file, and also import other style files */
.body{
    margin: 0px;
}

.form-actions {
    wg-button {
        width: 100%;
        button {
            width: 100%;
        }
    }
}

.page-heading {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

.btn-a {
    all: unset !important;
}
